@font-face {
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: normal;
  src: local("Baloo 2 Regular"), local("Baloo-2-Regular"), url("../fonts/baloo-2-v1-latin-regular.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: 500;
  src: local("Baloo 2 Medium"), local("Baloo-2-Medium"), url("../fonts/baloo-2-v1-latin-500.woff2") format("woff2");
  font-display: swap;
}
/*
 * [Modified] Modern CSS Reset
 * @link https://github.com/hankchizljaw/modern-css-reset
*/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p,
li,
h1,
h2,
h3,
h4 {
  word-break: break-word;
}

html,
body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  html {
    scroll-behavior: initial;
  }
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
  color: #070707;
  font-family: "Baloo 2", sans-serif;
}

main,
main.tdbc-container {
  padding-top: 1vh;
  padding-bottom: 5vh;
}

footer {
  margin-top: auto;
}

article {
  max-width: 80ch;
}
article > img {
  margin-right: auto;
  margin-left: auto;
}

.simple-flex-container {
  display: flex;
  flex-wrap: wrap;
}

.tdbc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 120ch;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

.tdbc-column-container {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(25ch, 1fr));
}

.tdbc-lg-column-container {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(35ch, 1fr));
  padding-left: 0px;
}

ul.tdbc-column-container {
  padding-left: 0;
}

.tdbc-section {
  width: 100%;
}
.tdbc-section--padded {
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.tdbc-section *:last-child {
  margin-bottom: 0;
}

.tdbc-hero {
  display: grid;
  min-height: 10vh;
  place-content: center;
  text-align: center;
}

.tdbc-row {
  display: grid;
  grid-gap: 1rem;
}
@media (min-width: 80ch) {
  .tdbc-row {
    grid-auto-flow: column;
    grid-auto-columns: max-content;
  }
}
.tdbc-row--center-content {
  justify-content: center;
}
.tdbc-row--center-alignitems {
  align-items: center;
}

hr {
  margin-top: 4rem;
  margin-bottom: 4rem;
  border: 1px solid #7935fe;
}

#tdbc-siteid {
  font-size: 2rem;
  text-decoration: none;
}

.tdbc-sitenav {
  background-color: #0a0a0a;
}
.tdbc-sitenav__container, .tdbc-sitenav__navlist {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tdbc-sitenav__container {
  justify-content: space-between;
  width: 100%;
  max-width: 120ch;
  margin: 0 auto;
  padding: 0.25rem 0.5rem;
}
.tdbc-sitenav__navlist li + li {
  margin-left: 0.5rem;
}
.tdbc-sitenav,
.tdbc-sitenav a:not(.tdbc-button) {
  color: #fff;
}
.tdbc-sitenav a:not(.tdbc-button):focus {
  border-radius: 4px;
  outline-color: transparent;
  outline-style: solid;
  box-shadow: 0 0 1px 2px #fff;
}

h1,
.tdbc-h1 {
  font-weight: 500;
  line-height: 1.15;
  margin-bottom: 0.65em;
}

h2,
.tdbc-h2 {
  font-weight: 500;
  line-height: 1.15;
  margin-bottom: 0.65em;
}

h3,
.tdbc-h3 {
  font-weight: 500;
  line-height: 1.15;
  margin-bottom: 0.65em;
}

h4,
.tdbc-h4 {
  font-weight: 500;
  line-height: 1.15;
  margin-bottom: 0.65em;
}

h1,
.tdbc-h1 {
  font-size: 4.2rem;
  font-size: min(max(3rem, 5vw), 4.2rem);
}

h2,
.tdbc-h2 {
  font-size: 3.15rem;
  font-size: min(max(2.5rem, 5vw), 3.15rem);
}

h3,
.tdbc-h3 {
  font-size: 2rem;
}

h4,
.tdbc-h4 {
  font-size: 1.35rem;
  color: #555555;
}

p,
li {
  font-size: 1.15rem;
}

p {
  margin: 0 0 1rem;
}

article > ul > li + li,
article > ol > li + li {
  margin-top: 1rem;
}
article * + h2 {
  margin-top: 4rem;
}
article * + h3 {
  margin-top: 2rem;
}

strong {
  font-weight: 500;
}

a:not(.tdbc-button) {
  color: #0a0a0a;
}

code:not([class*=language]) {
  display: inline-flex;
  padding: 0.15em;
  background-color: #fff;
  color: #555555;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  border: 1px solid #aaaaaa;
  border-radius: 2px;
  font-size: 0.75em;
  line-height: 1.1;
}

pre code:not([class*=language]) {
  width: 100%;
  max-width: calc(100vw - 2rem);
  overflow-x: auto;
}

blockquote {
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.5rem;
  border-left: 2px solid #7935fe;
  color: #555555;
}
blockquote,
blockquote p {
  font-size: 1.35rem;
}
blockquote + * {
  margin-top: 3rem;
}
blockquote :last-child {
  margin-bottom: 0;
}

.tdbc-lead {
  font-size: 1.35rem;
  letter-spacing: 0.01em;
  margin-bottom: 0.5em;
  line-height: 0.7;
}

.tdbc-list-unstyled {
  list-style: none;
  padding: 0;
}

.tdbc-button {
  display: inline-flex;
  align-items: center;
  align-self: start;
  justify-content: center;
  min-width: 10ch;
  min-height: 44px;
  padding: 0.25em 1em;
  transition: 180ms ease-in-out;
  transition-property: background, border;
  border-radius: 4px;
  background-color: #7935fe;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (-ms-high-contrast: active) {
  .tdbc-button {
    border: 2px solid currentColor;
  }
}
.tdbc-button i {
  margin-left: 10px;
}
.tdbc-button:focus {
  outline-color: transparent;
  outline-style: solid;
  box-shadow: 0 0 0 3px #070707;
}
.tdbc-button--small {
  min-height: 34px;
  font-size: 1rem;
}
.tdbc-button--center {
  align-self: center;
  margin-right: auto;
  margin-left: auto;
}
.tdbc-button__icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.25em;
}
.tdbc-button__icon--end {
  margin-right: 0;
  margin-left: 0.25em;
}
.tdbc-button__icon svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.tdbc-button-outlined {
  border: 2px solid #0a0a0a;
  background-color: transparent;
  color: #0a0a0a;
}
.tdbc-button-outlined:hover, .tdbc-button-outlined:focus {
  background-color: #cecece;
}
.tdbc-button--secondary {
  background-color: #7935fe;
}
.tdbc-button--secondary:focus {
  box-shadow: 0 0 0 3px #4901d6;
}
.tdbc-button-outlined--secondary {
  border-color: #a171fe;
  color: #7935fe;
}
.tdbc-button-outlined--secondary:focus {
  box-shadow: 0 0 0 3px #4901d6;
}
.tdbc-button-outlined--secondary:hover, .tdbc-button-outlined--secondary:focus {
  background-color: #f2ebff;
}
.tdbc-button--light {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}
.tdbc-button--light:focus {
  box-shadow: 0 0 0 3px #b3b3b3;
}
.tdbc-button-outlined--light {
  border-color: white;
  color: #fff;
}
.tdbc-button-outlined--light:focus {
  box-shadow: 0 0 0 3px #b3b3b3;
}
.tdbc-button-outlined--light:hover, .tdbc-button-outlined--light:focus {
  background-color: rgba(255, 255, 255, 0.15);
}
.tdbc-button--gray {
  background-color: #555555;
}
.tdbc-button--gray:focus {
  box-shadow: 0 0 0 3px #3c3c3c;
}
.tdbc-button-outlined--gray {
  border-color: #9a7676;
  color: #555555;
}
.tdbc-button-outlined--gray:focus {
  box-shadow: 0 0 0 3px #3c3c3c;
}
.tdbc-button-outlined--gray:hover, .tdbc-button-outlined--gray:focus {
  background-color: #eeeeee;
}

.tdbc-button__icon,
.tdbc-icon-button {
  display: inline-flex;
  position: relative;
  width: 1em;
  height: 1em;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}
.tdbc-button__icon svg,
.tdbc-icon-button svg {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.tdbc-icon-button svg {
  fill: currentColor;
  opacity: 0.7;
}
.tdbc-icon-button:focus, .tdbc-icon-button:hover {
  outline: none;
}
.tdbc-icon-button:focus svg, .tdbc-icon-button:hover svg {
  opacity: 1;
}
.tdbc-icon-button:focus::after {
  position: absolute;
  top: -0.3em;
  right: -0.3em;
  bottom: -0.3em;
  left: -0.3em;
  border: 1px solid currentColor;
  border-radius: 50%;
  content: "";
}

.tdbc-card {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  /* stylelint-disable selector-max-type, selector-no-qualifying-type */
}
.tdbc-card__title {
  margin-bottom: 1rem;
  font-size: 1.35rem;
  line-height: 1.15;
  text-decoration: none;
}
.tdbc-card a.tdbc-card__title::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 180ms box-shadow ease-in;
  border-radius: 8px;
  content: "";
}
.tdbc-card a.tdbc-card__title:focus {
  outline: none;
}
.tdbc-card a.tdbc-card__title:focus::before {
  box-shadow: 0 0 0 3px #0a0a0a;
}
.tdbc-card__content {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
}
.tdbc-card__content a:last-child {
  align-self: start;
  margin-top: auto;
}
.tdbc-card__content p {
  position: relative;
  margin: 0 0 1rem;
  z-index: 1;
}
.tdbc-card__content > ul {
  list-style-type: disc;
  padding-left: 1rem;
}
.tdbc-card__content > ul li + li {
  margin-top: 0.5rem;
}
.tdbc-card:hover a.tdbc-card__title::before {
  box-shadow: 0 0 0 3px #0a0a0a;
}
.tdbc-card img {
  padding: 5px;
}
.tdbc-card img:first-child {
  object-fit: cover;
  font-size: 14px;
  max-height: 15em;
  border-radius: 8px 8px 0 0;
}
.tdbc-card--outlined {
  border: 1px solid #aaaaaa;
  background-color: transparent;
  box-shadow: none;
}

.tdbc-child-card {
  min-width: 19%;
  /* stylelint-disable selector-max-type, selector-no-qualifying-type */
}
.tdbc-child-card__title {
  margin-bottom: 1rem;
  font-size: 1.35rem;
  line-height: 1.15;
  text-decoration: none;
}
.tdbc-child-card a.tdbc-child-card__title::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 180ms box-shadow ease-in;
  border-radius: 8px;
  content: "";
}
.tdbc-child-card a.tdbc-child-card__title:focus {
  outline: none;
}
.tdbc-child-card a.tdbc-child-card__title:focus::before {
  box-shadow: 0 0 0 3px #0a0a0a;
}
.tdbc-child-card__content {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
  text-align: center;
}
.tdbc-child-card__content img {
  margin-left: auto;
  margin-right: auto;
}
.tdbc-child-card__content a:last-child {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}
.tdbc-child-card__content p {
  position: relative;
  margin: 0 0 1rem;
  z-index: 1;
}
.tdbc-child-card__content > ul {
  list-style-type: disc;
  padding-left: 1rem;
}
.tdbc-child-card__content > ul li + li {
  margin-top: 0.5rem;
}
.tdbc-child-card:hover a.tdbc-child-card__title::before {
  box-shadow: 0 0 0 3px #0a0a0a;
}
.tdbc-child-card img {
  padding: 5px;
}
.tdbc-child-card img:first-child {
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}
.tdbc-child-card--outlined {
  border: 1px solid #aaaaaa;
  background-color: transparent;
  box-shadow: none;
}

/**
 * a11y-dark theme for JavaScript, CSS, and HTML
 * Based on the okaidia theme: https://github.com/PrismJS/prism/blob/gh-pages/themes/prism-okaidia.css
 * @author ericwbailey
 */
code[class*=language-],
pre[class*=language-] {
  color: #555555;
  background: none;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}

/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 2rem 0;
  overflow: auto;
  border-radius: 0.3em;
  max-width: calc(100vw - 2rem);
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #fff;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #76747f;
}

.token.punctuation {
  color: #555555;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: #4d48e8;
}

.token.boolean,
.token.number {
  color: #348614;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #171392;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.language-scss .token.string,
.style .token.string,
.token.variable {
  color: #d92d7a;
}

.token.function {
  color: #6f34a3;
}

.token.atrule,
.token.attr-value {
  color: #6b65e8;
}

.token.keyword {
  color: #171392;
}

.token.regex,
.token.important {
  color: #348614;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

@media screen and (-ms-high-contrast: active) {
  code[class*=language-],
pre[class*=language-] {
    color: windowText;
    background: window;
  }

  :not(pre) > code[class*=language-],
pre[class*=language-] {
    background: window;
  }

  .token.important {
    background: highlight;
    color: window;
    font-weight: normal;
  }

  .token.atrule,
.token.attr-value,
.token.function,
.token.keyword,
.token.operator,
.token.selector {
    font-weight: bold;
  }

  .token.attr-value,
.token.comment,
.token.doctype,
.token.function,
.token.keyword,
.token.operator,
.token.property,
.token.string {
    color: highlight;
  }

  .token.attr-value,
.token.url {
    font-weight: normal;
  }
}
.tdbc-content-maxlength {
  max-width: 80ch;
}

.tdbc-anchor {
  display: inline-block;
  margin-left: 0.25em;
  transition: 180ms opacity ease-in-out;
  opacity: 0.6;
  font-size: 0.65em;
  vertical-align: top;
}
.tdbc-anchor:hover {
  opacity: 1;
}

.tdbc-text-align--left {
  text-align: left;
}

.tdbc-text-align--center {
  text-align: center;
}
.tdbc-text-align--center > a {
  align-self: center !important;
}

.tdbc-text-align--right {
  text-align: right;
}

.tdbc-background--primary {
  background-color: #0a0a0a;
}

.tdbc-background--secondary {
  background-color: #7935fe;
}

.tdbc-background--background {
  background-color: #f9f9f9;
}

.tdbc-background--gray {
  background-color: #555555;
}

.tdbc-background--light {
  background-color: #fff;
}

.tdbc-ink--primary,
a.tdbc-ink--primary {
  color: #0a0a0a;
}

.tdbc-ink--primary > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--secondary,
a.tdbc-ink--secondary {
  color: #7935fe;
}

.tdbc-ink--secondary > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--text,
a.tdbc-ink--text {
  color: #070707;
}

.tdbc-ink--text > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--gray,
a.tdbc-ink--gray {
  color: #555555;
}

.tdbc-ink--gray > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--light,
a.tdbc-ink--light {
  color: #fff;
}

.tdbc-ink--light > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--dark,
a.tdbc-ink--dark {
  color: rgba(0, 0, 0, 0.87);
}

.tdbc-ink--dark > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.tdbc-my-sm {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.tdbc-mt-sm {
  margin-top: 0rem;
}

.tdbc-my-md {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tdbc-mt-md {
  margin-top: 1rem;
}

.tdbc-my-lg {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tdbc-mt-lg {
  margin-top: 2rem;
}

.tdbc-my-xl {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.tdbc-mt-xl {
  margin-top: 4rem;
}

.tdbc-my-auto {
  margin-top: "auto";
  margin-bottom: "auto";
}

.tdbc-mt-auto {
  margin-top: "auto";
}

.tdbc-mb-none {
  margin-bottom: 0;
}

form p.hidden {
  visibility: hidden;
  max-height: 0px;
}

form p.hidden input {
  visibility: hidden;
}
